.App {
    background-color: #282c34;
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.App-logo {
    max-height: 12vmin;
    min-height: 7vmax;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 1s linear alternate;
    }
}

.App-header {
    margin-top: 10px;
    min-height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Courier New';
}

.header {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #ea7605;
    font-size: 3em;
}

.pageCount {
    padding: 30px 50px;
    flex-flow: wrap;
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-family: 'Courier New';
    font-size: 3em;
    margin-top: 50px;
    color: white;
}

.pageCountHidden {
    margin-top: 20px;
    visibility: hidden;
}

.pageHitsText {
    font-size: 0.35em;
    padding: 0 20px;
}

.item-appear {
    opacity: 0;
}
.item-appear-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(-1deg);
    }
    to {
        transform: rotate(-7deg);
    }
}
