.reminder-module {
    padding: 0 20px 20px 20px;
    min-height: fit-content;
    font-family: 'Courier New';
}

#reminder-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.react-datepicker-time__input {
    min-width: fit-content;
    max-width: 70px;
    font-size: 14px;
}

input.react-datepicker-time__input {
    border: 1px orange solid;
}

.react-datepicker__header__dropdown--select {
    margin-top: 7px;
}

.datePicker {
    min-width: fit-content;
}

.confirm-reminder-button {
    padding: 10px;
    margin: 20px;
    max-width: 500px;
    min-width: 300px;
    margin: 50px;
    font-size: 20px;
}

label {
    font-size: 24px;
    padding: 20px 10px 0 10px;
    flex-flow: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.error {
    color: red;
}

input,
textarea,
button {
    margin: 0 10px 0 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: none;
    border-style: none;
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

input {
    width: 70%;
}

textarea {
    width: 40%;
    height: 100px;
}
